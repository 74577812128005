import {
  type HTMLAttributes,
  createContext,
  useContext,
  useState,
} from "react";
import type { KeywordListAnalysisGroup } from "~/types";

type Context = {
  inputText: string;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  analysis: KeywordListAnalysisGroup[];
  setAnalysis: React.Dispatch<React.SetStateAction<KeywordListAnalysisGroup[]>>;
  questionCount: number;
  setQuestionCount: React.Dispatch<React.SetStateAction<number>>;
};

const context = createContext<Context>({} as any);

export function useInputText() {
  return useContext(context);
}

export function InputTextProvider({
  children,
}: HTMLAttributes<HTMLDivElement>) {
  const [inputText, setInputText] = useState("");
  const [analysis, setAnalysis] = useState<KeywordListAnalysisGroup[]>([]);
  const [questionCount, setQuestionCount] = useState(0);
  const value = {
    inputText,
    setInputText,
    analysis,
    setAnalysis,
    questionCount,
    setQuestionCount,
  };
  return <context.Provider value={value}>{children}</context.Provider>;
}
